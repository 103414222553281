import { DialogContent, Divider, Typography } from "@mui/material";
import GscanDialogAuth from "./GscanDialogAuth";
import GscanDialogRequest from "./GscanDialogRequest";
import GscanDialogResponse from "./GscanDialogResponse";

export default function GscanDialogContent({ onToggleHabilitarEndpoint, onSave, data }) {
  const getEndpointByTipo = (tipo) => {
    const endpoint = data
      ? data.endpoints.find((x) => x.tipoEndpoint === tipo)
      : null;
    if (endpoint) return endpoint;
    return {
      idGetnetScan: 0,
      idComercio: data?.idComercio,
      tipoEndpoint: tipo,
      urlEndpoint: "",
      clientId: "",
      clientSecret: "",
      parametros: {},
      habilitado: false,
    };
  };
  const ENDPOINT_AUTH = 1;
  const ENDPOINT_REQUEST = 2;
  const ENDPOINT_RESPONSE = 3;
  return (
    <div style={{ padding: '15px' }}>
      <Typography variant="h5" style={{ marginLeft: '15px' }}>
        {data.nombreComercio}
      </Typography>
      <br />
      <DialogContent dividers>
        <GscanDialogAuth
          onToggleHabilitarEndpoint={onToggleHabilitarEndpoint}
          endpoint={getEndpointByTipo(ENDPOINT_AUTH)}
          onSave={onSave}
        />
        <Divider />
        <br />
        <GscanDialogRequest
          onToggleHabilitarEndpoint={onToggleHabilitarEndpoint}
          endpoint={getEndpointByTipo(ENDPOINT_REQUEST)}
          onSave={onSave}
        />
        <Divider />
        <br />
        <GscanDialogResponse
          onToggleHabilitarEndpoint={onToggleHabilitarEndpoint}
          endpoint={getEndpointByTipo(ENDPOINT_RESPONSE)}
          onSave={onSave}
        />
      </DialogContent>
    </div>
  )
}
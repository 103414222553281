import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ShadowBox from "../../components/ShadowBox";
import RoundButton from "../../components/RoundButton";
import PosTable from "../../components/PosTable";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { obtenerTodosLosPosIdComercio } from "../../connection/mantenedores/posMantenedor";
import { obtenerTodosLosComerciosRequest } from "../../connection/mantenedores/comercioMantenedor";
import { getToken } from "../../connection/tokenController";
import { useLoader } from "../../context/LoaderContext";
import Combobox from "../../components/Combobox";
import FiltroTexto from "../../components/FiltroTexto";
import { getIdComercio, isComercioUserRole } from "../../connection/loginController";

function parsearComerciosACombobox(listado) {
  return listado.map((x) => ({
    nombre: x.nombreComercio.toUpperCase(),
    codigo: x.idComercio,
  })).sort((a, b) => a.nombre.localeCompare(b.nombre));
}

function parsearSucursalesACombobox(listado) {
  const ids = [];
  listado = listado.filter((x) => {
    if (!ids.includes(x.idSucursal)) {
      ids.push(x.idSucursal);
      return x;
    }
    return false;
  });
  return listado
    .map((x) => ({
      nombre: `${x.idSucursal}`,
      codigo: x.idSucursal,
    }))
    .sort((a, b) => a.codigo - b.codigo); 
}
export const SELECTED_COMMERCE_POS = "pos_sel_comm";
export const POS_MANTENEDOR_INPAGE = "pos_man_in_page";

export default function PosMantenedor() {
  const navigate = useNavigate();
  const { showLoader, hideLoader, showNotification } = useLoader();
  const [comercios, setComercios] = useState([]);
  const [sucursales, setSucursales] = useState([]);
  const [idComercio, setIdComercio] = useState(getIdComercio()||""); 
  const [listadoPos, setListadoPos] = useState([]);
  const [selectedSucursal, setSelectedSucursal] = useState(0);
  const [showListado, setShowListado] = useState([]);
  const [selectedCommerce, setSelectedCommerce] = useState({
    codigo: -1,
    nombre: ""
  })

  const handleEdit = (row) => {
    const com = comercios.find(x => x.codigo === row.idComercio);
    localStorage.setItem(SELECTED_COMMERCE_POS, JSON.stringify(com));
    navigate("/pos/editar-pos", { state: { pos: row } });
  };

  const obtenerListadoPos = async () => {
    try {
      if (!idComercio) {
        setListadoPos([]);
        return;
      }
      showLoader();
      const token = getToken();
      const listadoComercios = await obtenerTodosLosPosIdComercio(idComercio, token);
      const listado = listadoComercios.filter((x) => x.habilitado);
      handleEstablecerSucursalesComobox(listado);
      // Establecer el estado con el nuevo listado
      setListadoPos(listado);
      setShowListado(listado);
    } catch (error) {
      if (error.status === 500) {
        showNotification("error", "Error de conexión");
        return;
      }
      showNotification("error", "Ha ocurrido un error inesperado");
    } finally {
      hideLoader();
    }
  };

  const handleSelectSucursal = (row) => {
    setSelectedSucursal(row);
  };

  const handleUnSelectSucursal = () => {
    setSelectedSucursal({codigo: -1, nombre: ''});
  };

  useEffect(() => {
    if (idComercio) {
      obtenerListadoPos();
      return;
    }
    
    setListadoPos([]);    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idComercio]);

  useEffect(() => {
    handleEstablecerComercios();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleEstablecerComercios = async () => {
    try {
      showLoader();
      const listadoComercios = await obtenerTodosLosComerciosRequest(getToken());
      setComercios(parsearComerciosACombobox(listadoComercios.filter((comercio) => comercio.habilitado)));
    } catch (error) {
      if (error.status === 500) {
        showNotification("error", "Error de conexión");
        return;
      }
      showNotification("error", "Ha ocurrido un error inesperado");
    } finally {
      hideLoader();
    }
  };

  const handleEstablecerSucursalesComobox = (listado) => {
    setSucursales(parsearSucursalesACombobox(listado));
  };

  const handleSelectComercio = (row) => {
    localStorage.setItem(SELECTED_COMMERCE_POS, JSON.stringify(row));
    setSelectedCommerce(row)
    setIdComercio(row.codigo);
  };

  const handleUnSelectComercio = () => {
    localStorage.setItem(SELECTED_COMMERCE_POS, "");
    setIdComercio("");
    setSucursales([]);
    setSelectedSucursal({codigo: -1, nombre: ''});
  };

  const handleFiltrar = (texto) => {
    if (texto.length === 0) {
      setShowListado(listadoPos);
      return;
    }

    const list = listadoPos.filter((pos) => {
      if (pos.serialNumber.includes(texto)) {
        return pos;
      }
      if (`${pos.idTerminal}`.includes(texto)) {
        return pos;
      }
      return null;
    });

    setShowListado(list);
  };
  const setComercioInicial = () => {
    try {
      const inPage = localStorage.getItem(POS_MANTENEDOR_INPAGE);
      if (!inPage || inPage !== "true"){
        return;
      }
      const comercioSeleccionado = JSON.parse(localStorage.getItem(SELECTED_COMMERCE_POS));
      setSelectedCommerce(comercioSeleccionado);
      if (comercioSeleccionado.codigo) {
        setIdComercio(comercioSeleccionado.codigo)
      }else {
        setIdComercio(comercioSeleccionado.idComercio)
      }
      localStorage.getItem(POS_MANTENEDOR_INPAGE, "false");
    }catch{
    }
  }
  useEffect(() => {
    setComercioInicial();
  }, [comercios])

  return (
    <>
      <Typography variant="h5" sx={{ margin: '1rem 0' }}>TERMINALES POS</Typography>
      <br />
      <ShadowBox style={{ padding: '2rem' }}>
        {isComercioUserRole() ? (
          <>
          </>
        ) : (
          <>
            <Link
              to="/pos/nuevo-pos"
              style={{ color: "black", textDecoration: "none", float: "right" }}
            >
              <RoundButton variant="outlined">AGREGAR</RoundButton>
            </Link>
            <br />
            <label style={{ color: "black" }}>SELECCIONE COMERCIO</label>
            <Combobox
              rows={comercios || []}
              placeholder="Comercio"
              onFilter={() => {}} 
              onSelect={handleSelectComercio}
              onUnSelect={handleUnSelectComercio}
              selected={selectedCommerce}
            />
          </>
        )}
        <br />
        <label style={{ color: "black" }}>SELECCIONE SUCURSAL</label>
        <Combobox
          rows={sucursales || []}
          placeholder="Sucursal"
          onFilter={() => {}}
          onSelect={handleSelectSucursal}
          onUnSelect={handleUnSelectSucursal}
          selected={selectedSucursal}
        />
        <br />
        <label style={{ color: "black" }}>BUSCAR SERIAL</label>
        <FiltroTexto type="text" placeholder="Serial number, Id terminal..." onFilter={handleFiltrar} />
        <br />
        <PosTable
          listado={showListado}
          selectedSucursal={selectedSucursal.codigo}
          onEdit={handleEdit}
        />
      </ShadowBox>
    </>
  );
}

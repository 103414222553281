import React, { useCallback, useRef } from "react";
import { Box, Typography } from "@mui/material";
import ShadowBox from "../../components/ShadowBox";
import logo from "../../resources/images/logo.png";
import InputPassword from "../../components/InputPassword";
import Button from "../../components/Button";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Input from "../../components/Input";
import { useLoader } from "../../context/LoaderContext";
import { authUsuario } from "../../connection/mantenedores/usuarioMantenedor";
import ReCAPTCHA from "react-google-recaptcha";
import { saveToken } from "../../connection/tokenController";
import {
  saveAdminUser,
  saveUserCambioClave,
  saveUserRole,
} from "../../connection/loginController";
import { validarEmail } from "../../utils/utilidades";
import { VERSION } from "../../version";

const CaptchaSiteKey = process.env.REACT_APP_CAPTCHA_SITE_KEY;

const styles = {
  box: {
    height: "100vh",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    background: "linear-gradient(0deg, #D4E8FF, white)",
  },
  shadowBox: {
    borderRadius: "15px",
    padding: '2rem 1.5rem',
    textAlign: 'center'
  },
  imgLogo: {
    width: "12rem",
  },
  olvidadPassword: {
    color: "#c1c1c1",
  },
  versionStyle: {
    textAlign: 'center',
    fontStyle: 'italic',
    color: '#444',
    position: 'absolute',
    bottom: '1rem',
    left: '50%',
    transform: 'translate(-50%, 0)'
  }
};

export default function Login() {
  const navigate = useNavigate();
  const { showLoader, hideLoader, showNotification } = useLoader();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const reCaptchaRef = useRef(null);
  const [recaptchaValidated, setRecaptchaValidated] = useState(false);
  const [reCaptchaToken, setReCaptchaToken] = useState("");

  const handleChangeUsername = (e) => setUsername(e.target.value);
  const handleChangePassword = (e) => setPassword(e.target.value);

  const handleRecaptchaChange = useCallback((token) => {
    setReCaptchaToken(token);
    setRecaptchaValidated(!!token);
  }, []);

  const resetRecaptcha = () => {
    try {
      setRecaptchaValidated(false);
      if (reCaptchaRef.current) reCaptchaRef.current.reset();
    } catch (error) {
      console.error('no se pudo restablecer el captcha');
    }
  };
  const handleLoginClick = (e) => {
    e.preventDefault();
    setError('');
    if (!validarDatos()) return;
    if (!validarRecaptcha()) return;
    handleLogin();
  };
  const validarDatos = () => {
    if (!username.trim() || !password.trim()) {
      setError("Por favor, complete todos los campos");
      return false;
    }
    if (!validarEmail(username)) {
      setError("Por favor, introduce un correo electrónico válido.");
      return false;
    }
    return true;
  };

  const validarRecaptcha = () => {
    if (!recaptchaValidated) {
      setError("Valide el captcha por favor");
      return false;
    }
    return true;
  };
  const handleLogin = async () => {
    showLoader();
    try {
      const respuesta = await authUsuario(username, password, reCaptchaToken);
      handleLoginCorrect(respuesta);
    } catch (err) {
      if (err.status === 500) {
        showNotification("error", "Error de conexión");
      }
      if (err.status === 401) {
        setError("Introduce las credenciales correctas.");
      }
      if (err.status === 400) {
        const error = await err.json();
        setError(error.detail)
      }
      showNotification("error", "Ha ocurrido un error");
      resetRecaptcha();
    } finally {
      hideLoader();
    }
  };
  const handleLoginCorrect = (respuesta) => {
    try {
      if (respuesta.cambioDeClave) {
        saveUserRole(respuesta)
        saveUserCambioClave(username, password);
        handleNavigate("/cambio-clave");
        return;
      }
      if (respuesta.token === "") {
        setError("Introduce las credenciales correctas.");
        resetRecaptcha();
        return;
      }
      saveToken(respuesta.token, respuesta.expires_in);
      if (username === "admin@ione.cl") {
        saveAdminUser();
        handleNavigate("/usuarios");
        return;
      }

      saveUserRole(respuesta)
      handleNavigate("/comercios");
    } catch (err) {
      console.error(err);
      setError(
        "Error en la autenticación. Por favor, verifica tus credenciales."
      );
      resetRecaptcha();
    } finally {
      hideLoader();
    }
  };
  const handleNavigate = (to) => {
    showNotification("success", "Inicio de sesión exitoso");
    navigate(to);
  };

  return (
    <>
      <Box sx={styles.box}>
        <ShadowBox style={styles.shadowBox}>
          <img style={styles.imgLogo} src={logo} alt="logo" />
          <br />
          <Typography variant="p" style={styles.labelLogin} gutterBottom>
            INICIAR SESIÓN
          </Typography>
          <br />
          <br />
          <form action="#">
            <Input
              className="gray"
              required
              onChange={handleChangeUsername}
              value={username}
              placeholder="Correo electrónico"
              completeWidth={true}
            />
            <br />
            <InputPassword
              className="gray"
              value={password}
              onChange={handleChangePassword}
              completeWidth={true}
            />
            <br />
            <Typography sx={{ color: "#444 !important" }}><Link className="forgot_link" to="/olvide-mi-pass">Olvide mi contraseña</Link></Typography>
            <br />
            <div style={{ display: "flex", justifyContent: "center" }}>
              <ReCAPTCHA
                ref={reCaptchaRef}
                sitekey={CaptchaSiteKey}
                onChange={handleRecaptchaChange}
              />
            </div>
            <br />
            <div style={{ textAlign: "center", width: "100%" }}>
              {error && (
                <Typography
                  sx={{
                    color: "red",
                    wordWrap: "break-word",
                  }}
                >
                  {error}
                </Typography>
              )}
            </div>
            <br />
            <Button onClick={handleLoginClick} largeButton>
              INICIAR SESIÓN
            </Button>
          </form>
        </ShadowBox>
        <Typography sx={styles.versionStyle}
        >Versión: {VERSION}</Typography>
      </Box>
    </>
  );
}

import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  TextField,
  Box,
} from "@mui/material";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

export function WarningDialog({ open, onClose, onConfirm }) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            margin: "5px",
            alignContent: "center",
            height: "100px",
          }}
        >
          <WarningAmberIcon sx={{ fontSize: 70 }} color="error" />
          <Typography variant="h5" sx={{ mt: 1 }}>
            Advertencia
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
      <Typography sx={{ textAlign: "center" }}>
      Modificar este campo podría afectar el funcionamiento de la aplicación en los POS. Proceda con precaución.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancelar</Button>
        <Button onClick={onConfirm} color="primary">
          Aceptar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export function ConfirmationDialog({
  open,
  onClose,
  onConfirm,
  confirmationText,
  setConfirmationText,
  confirmationError,
  setConfirmationError,
}) {
  const handleConfirmSave = async () => {
    if (confirmationText.trim() === "ACEPTAR") {
      setConfirmationError(false);
      setConfirmationText("");
      await onConfirm();
      onClose();
    } else {
      setConfirmationError(true);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            margin: "5px",
            height: "100px",
          }}
        >
          <CheckCircleOutlineIcon sx={{ fontSize: 70 }} color="success" />
          <Typography variant="h5" sx={{ mt: 1 }}>
            Validar Cambio Endpoint
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Typography sx={{ textAlign: "center" }}>
          Por favor, escriba "ACEPTAR" en el campo de abajo para confirmar la
          acción de guardado.
        </Typography>
        <TextField
          margin="normal"
          label="Palabra de confirmación"
          fullWidth
          value={confirmationText}
          onChange={(e) => setConfirmationText(e.target.value)}
          error={confirmationError}
          helperText={
            confirmationError
              ? "Debe escribir 'ACEPTAR' en mayúsculas para confirmar."
              : ""
          }
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancelar</Button>
        <Button onClick={handleConfirmSave} color="primary">
          Aceptar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

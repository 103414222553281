import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import ShadowBox from "../../components/ShadowBox";
import Button from "../../components/Button";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Combobox from "../../components/Combobox/Combobox";
import { obtenerTodosLosComerciosRequest } from "../../connection/mantenedores/comercioMantenedor";
import {
  crearPosRequest,
  editarPosRequest,
} from "../../connection/mantenedores/posMantenedor";
import {
  getToken,
} from "../../connection/tokenController";
import Input from "../../components/Input";
import { useLoader } from "../../context/LoaderContext";
import { isProduction, validarTextoNumero } from "../../utils/utilidades";
import CustomBreadcrumbs from "../../components/CustomBreadcrumbs";
import { POS_MANTENEDOR_INPAGE, SELECTED_COMMERCE_POS } from "./PosMantenedor";

const breadcrumbItems = [
  { href: '/pos', label: "POS" }
];


export default function EditarCrearPos(props) {
  let location = useLocation();
  let navigate = useNavigate();
  const { showLoader, hideLoader, showNotification } = useLoader();

  const [isEdit, setIsEdit] = useState(false);
  const [inputError, setInputError] = useState("");
  const [comercios, setComercios] = useState([]);
  const [isComercioSelected, setIsComercioSelected] = useState(false)

  const handleVolver = (e) => {
    e.preventDefault();
    navigate("/pos");
  };

  const [pos, setPos] = useState({
    idComercio: "",
    idTerminal: "",
    idSucursal: "",
    serialNumber: "",
    habilitado: true,
  });

  useEffect(() => {
    const statePos = location.state?.pos;
    if (statePos) {
      setPos(statePos);
      setIsEdit(true);
    }
    handleSetComercios();
    handleSetIdComercio()
    if (isProduction()) {
      navigate('/pos');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeCodigoTerminal = (e) => {
    if (!(/^[0-9]*$/).test(e.target.value)) return;
    if (e.target.value.length > 8) return;
    const dato = validarTextoNumero(e.target.value);
    setPos({ ...pos, idTerminal: `${dato}` });
  };
  const handleChangeCodigoSucursal = (e) => {
    if (!(/^[0-9]*$/).test(e.target.value)) return;
    if (e.target.value.length > 6) return;
    const dato = validarTextoNumero(e.target.value);
    setPos({ ...pos, idSucursal: `${dato}` });
  };
  const handleChangeNumeroSerie = (e) => {
    if (e.target.value.length > 12) return;
    setPos({ ...pos, serialNumber: e.target.value });
  };

  const handleCrear = async (e) => {
    e.preventDefault();
    showLoader();
    if (
      !pos.idComercio.toString().trim() ||
      !pos.idTerminal.trim() ||
      !pos.idSucursal.trim() ||
      !pos.serialNumber.trim()
    ) {
      setInputError("Por favor, complete todos los campos.");
      hideLoader();
      return;
    }
    const objeto = { ...pos, habilitado: true };

    setInputError("");
    try {
      const token = getToken();
      const respuesta = await crearPos(objeto, token);
      if (!respuesta) {
        return;
      }
      showNotification("success", "Pos creado con éxito");
        hideLoader();
        navigate('/pos');
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  };
  
  const handleEditar = async (e) => {
    e.preventDefault();
    showLoader();
    if (
      !pos.idComercio.toString().trim() ||
      !pos.idTerminal.toString().trim() ||
      !pos.idSucursal.toString().trim() ||
      !pos.serialNumber.toString().trim()
    ) {
      setInputError("Por favor, complete todos los campos.");
      hideLoader();
      return;
    }
    // Crear un nuevo objeto sin la propiedad nombreComercio
    const objeto = { ...pos, habilitado: true };
    const token = getToken();
    try {
      // Enviar el nuevo objeto sin la propiedad nombreComercio
      const respuesta = await editarPos(objeto, pos.idPosData, token);
      if (!respuesta) {
        return;
      }
      showNotification("success", "Pos editado con éxito");
      navigate("/pos");
    } catch (error) {
    } finally {
      hideLoader();
    }
  };
  const handleSetComercios = async () => {
    try {
      const token = getToken();
      showLoader();
      const response = await obtenerComercios(token);

      const comerciosHabilitados = response.filter(
        (comercio) => comercio.habilitado
      );
      const comerciosConContent = comerciosHabilitados.map((comercio) => ({
        ...comercio,
        content: comercio.nombreComercio,
      }));
      const nuevoArray = comerciosConContent.map(
        ({ nombreComercio, idComercio }) => ({ codigo: idComercio, nombre: nombreComercio.toUpperCase() })
      );
      nuevoArray.sort((a, b) => a.nombre.localeCompare(b.nombre));
      setComercios(nuevoArray);
    } catch (error) {
      console.error(error);
    }
    finally {
      hideLoader();
    }
  };

  const handleSelectCodigo = (comercio) => {
    setPos({ ...pos, idComercio: comercio.codigo });
  };

  const obtenerComercios = async (token) => {
    try {
      return await obtenerTodosLosComerciosRequest(token);
    } catch (error) {
      if (error.status === 500) {
        showNotification("error", "Error de conexión");
        return;
      }
      showNotification("error", "Ha ocurrido un error inesperado");
    }
    finally {
      hideLoader();
    }
  };

  const crearPos = async (pos, token) => {
    try {
      await crearPosRequest(pos, token);
      return true;
    } catch (error) {
      if (error.status === 500) {
        showNotification("error", "Error de conexión");
        return false;
      }
      showNotification("error", "Ha ocurrido un error inesperado");
      return false;
    }
  };

  const editarPos = async (pos, id, token) => {
    try {
      await editarPosRequest(pos, id, token);
      return true
    }
    catch (error) {
      if (error.status === 500) {
        showNotification("error", "Error de conexión");
        return false;
      }
      showNotification("error", "Ha ocurrido un error inesperado");
      return false;
    }
  };
  function handleSetIdComercio() {
    localStorage.setItem(POS_MANTENEDOR_INPAGE, "true");
    try {
    const comercioStr = localStorage.getItem(SELECTED_COMMERCE_POS);
    const comercio = JSON.parse(comercioStr);
    setPos(prev => ({...prev, idComercio: comercio.codigo}))
    setIsComercioSelected(true);
    }catch {

    }
    
  }
  useEffect(() => {
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <>
      <Typography variant="h5" sx={{ margin: '1rem 0' }}>{isEdit ? "EDITAR" : "NUEVO"} POS</Typography>
      <br />
      <ShadowBox style={{ padding: '1rem 2rem' }}>
        <CustomBreadcrumbs items={[...breadcrumbItems, isEdit ? { label: 'Editar' } : { label: 'Crear' }]} />
        <form>
          {
            isEdit || isComercioSelected ? <h2>{JSON.parse(localStorage.getItem(SELECTED_COMMERCE_POS)).nombre}</h2> :
              <>
                <label style={{ color: "black" }}>SELECCIONE COMERCIO</label>
                <Combobox
                  rows={comercios || []} // Asegúrate de que listaComandos esté definida
                  placeholder="Seleccionar Comercio..."
                  onFilter={() => { }}
                  onSelect={handleSelectCodigo}
                />
                <br />
              </>
          }
          <br />
          <label style={{ color: "black" }}>CÓDIGO DE TERMINAL</label>
          <Input
            type="text"
            placeholder="Código de Terminal"
            value={pos.idTerminal}
            onChange={handleChangeCodigoTerminal}
          />
          <br />
          <label style={{ color: "black" }}>INGRESE SUCURSAL</label>
          <Input
            type="text"
            placeholder="Código de Sucursal"
            value={pos.idSucursal}
            onChange={handleChangeCodigoSucursal}
          />
          <br />
          <label style={{ color: "black" }}>NÚMERO DE SERIE</label>
          <Input
            type="text"
            placeholder="Numero de Serie"
            value={pos.serialNumber}
            onChange={handleChangeNumeroSerie}
          />
          <br />
          {inputError && (
            <div style={{ color: "red", marginTop: "10px" }}>{inputError}</div>
          )}
          <br />
          <Button
            style={{ marginRight: "10px" }}
            variant="gray"
            onClick={handleVolver}
          >
            CANCELAR
          </Button>
          {isEdit ? (
            <Button onClick={handleEditar}>EDITAR</Button>
          ) : (
            <Button onClick={handleCrear}>ACEPTAR</Button>
          )}
        </form>
      </ShadowBox>
    </>
  );
}

import { Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ShadowBox from "./ShadowBox";
import RoundButton from "./RoundButton";
import TransaccionesTable from "./TransaccionesTable";
import { getToken } from "../connection/tokenController";
import Combobox from "./Combobox";
import SelectorFecha from "./SelectorFecha";
import { useLoader } from "../context/LoaderContext";
import { obtenerTodosLosComerciosRequest } from "../connection/mantenedores/comercioMantenedor";
import { obtenerTodosLosPosIdComercio } from "../connection/mantenedores/posMantenedor";
import TransaccionDialog from "./TransaccionDialog";
import ChipList from "./ChipList";
import {
  getIdComercio,
  isComercioUserRole,
} from "../connection/loginController";
import ExportButton from "./Button/ExportButton";
import descomponerFechaHora from "./Button/decomponerFecha";

const MAX_DAYS_SPAN = 15;

const listaComandos = [
  { nombre: "100 | VENTA ", codigo: 100 },
  { nombre: "102 | ANULACIÓN", codigo: 102 },
  { nombre: "103 | CIERRE", codigo: 103 },
  { nombre: "105 | DETALLE DE VENTAS", codigo: 105 },
  { nombre: "108 | DEVOLUCIÓN", codigo: 108 },
  { nombre: "109 | DUPLICADO", codigo: 109 },
  { nombre: "117 | SERVICIO DE IMPRESIÓN", codigo: 117 },
];

function parsearComboboxComercios(listado) {
  return listado
    .map((x) => ({
      codigo: x.idComercio,
      nombre: x.nombreComercio.toUpperCase(),
    }))
    .sort((a, b) => a.nombre.localeCompare(b.nombre));
}

function parsearComboboxPos(listado) {
  return listado
    .map((x) => ({
      codigo: x.idPosData,
      nombre: x.serialNumber.toUpperCase(),
    }))
    .sort((a, b) => a.nombre.localeCompare(b.nombre));
}

function parsearComandos(listado) {
  return listado.map((x) => x.codigo).join(",");
}

const Transacciones = (props) => {
  const { showLoader, hideLoader, showNotification } = useLoader();

  const [comercios, setComercios] = useState([]);
  const [selectedIdComercio, setSelectedIdComercio] = useState(
    getIdComercio() || 0
  );
  const [listadoPos, setListadoPos] = useState([]);
  const [idPos, setIdPos] = useState("");
  const [comandos, setComandos] = useState([]);
  const [fechas, setFechas] = useState({
    fechaDesde: null,
    fechaHasta: null,
    maxDate: null,
  });
  const [transacciones, setTransacciones] = useState([]);
  const [excelInfo, setExcelInfo] = useState([]);
  const [dialogoJson, setDialogoJson] = useState({
    open: false,
    titulo: "",
    request: "",
    response: "",
  });

  const handleObtenerComercios = async () => {
    try {
      showLoader();
      const token = getToken();
      const response = await obtenerTodosLosComerciosRequest(token);
      setComercios(
        parsearComboboxComercios(response.filter((x) => x.habilitado))
      );
    } catch (error) {
      showNotification(
        error.status === 500
          ? "Error de conexión"
          : "Ha ocurrido un error inesperado"
      );
    } finally {
      hideLoader();
    }
  };

  const handleSelectComercio = (row) => {
    setSelectedIdComercio(row.codigo);
  };

  const handleUnselectComercio = () => {
    setSelectedIdComercio(0);
  };

  useEffect(() => {
    handleObtenerComercios();
    // eslint-disable-next-line
  }, [idPos]);

  const handleBuscarListadoDePos = async () => {
    try {
      showLoader();
      const token = getToken();
      const listadoPos = await obtenerTodosLosPosIdComercio(
        selectedIdComercio,
        token
      );
      setListadoPos(parsearComboboxPos(listadoPos.filter((x) => x.habilitado)));
    } catch (error) {
      showNotification(
        error.status === 500
          ? "Error de conexión"
          : "Ha ocurrido un error inesperado"
      );
    } finally {
      hideLoader();
    }
  };

  useEffect(() => {
    if (selectedIdComercio) {
      handleBuscarListadoDePos();
    }
    // eslint-disable-next-line
  }, [selectedIdComercio]);

  const handleSelectPos = (row) => {
    setIdPos(row.codigo);
  };

  const handleUnselectPos = () => {
    setIdPos("");
  };

  const handleSelectComando = (comando) => {
    if (!comandos.some((x) => x.codigo === comando.codigo)) {
      setComandos([...comandos, comando]);
    }
  };

  const handleDeleteComando = (comando) => {
    setComandos(comandos.filter((x) => x.codigo !== comando.codigo));
  };

  const handleSetFechaHasta = (fecha) => {
    setFechas((prev) => ({ ...prev, fechaHasta: fecha }));
  };

  const handleSetFechaDesde = (fecha) => {
    setFechas((prev) => ({
      ...prev,
      fechaDesde: fecha,
      maxDate: obtenerMaxDate(fecha),
    }));
  };

  const obtenerMaxDate = (fecha) => {
    const fechaMax = new Date(fecha);
    fechaMax.setDate(fecha.getDate() + MAX_DAYS_SPAN - 1);
    return fechaMax;
  };

  const formatearFechaObtenida = (fecha) => {
    if (!fecha) return "";
    const fechaObj = new Date(fecha);
    const dia = String(fechaObj.getDate()).padStart(2, "0");
    const mes = String(fechaObj.getMonth() + 1).padStart(2, "0");
    const anio = fechaObj.getFullYear();
    return `${anio}-${mes}-${dia}`;
  };

  const handleBuscarClick = async () => {
    try {
      if (!idPos) {
        showNotification("error","No se ha seleccionado ningún POS");
        return;
      }
      if (!props.onlyOneCommand && comandos.length === 0) {
        showNotification("error","No se ha seleccionado ningún comando");
        return;
      }
      if (!fechas.fechaDesde) {
        showNotification("error","Seleccione la fecha desde");
        return;
      }
      if (!fechas.fechaHasta) {
        showNotification("error","Seleccione la fecha hasta");
        return;
      }
      if (
        (fechas.fechaHasta - fechas.fechaDesde) / (1000 * 60 * 60 * 24) >
        MAX_DAYS_SPAN
      ) {
        showNotification(
          `Las fechas deben tener máximo ${MAX_DAYS_SPAN} días de diferencia`
        );
        return;
      }
      const fechaHastaBuscar = new Date(fechas.fechaHasta);
      fechaHastaBuscar.setDate(fechaHastaBuscar.getDate() + 1);
      const fechaDesdeStr = formatearFechaObtenida(fechas.fechaDesde);
      const fechaHastaStr = formatearFechaObtenida(fechaHastaBuscar);
      const comandosParseados = obtenerComandos();

      showLoader();
      const listado = await props.onBuscar(
        idPos,
        fechaDesdeStr,
        fechaHastaStr,
        comandosParseados
      );
      setTransacciones(listado);
    } catch (error) {
      showNotification("Error al buscar transacciones");
      console.error(error);
    } finally {
      hideLoader();
    }
  };

  const obtenerComandos = () => {
    if (props.onlyOneCommand) return `${props.onlyOneCommand}`;
    return parsearComandos(comandos);
  };

  useEffect(() => {
    const nombreComercio =
      comercios.find((comercio) => comercio.codigo === selectedIdComercio)
        ?.nombre || "";
    const obtenerFechaYHora = (fecha) => {
      const { fecha: fechaFormateada, hora } = descomponerFechaHora(
        new Date(fecha || "")
      );
      return { fecha: fechaFormateada || "", hora: hora || "" };
    };
    const dataExcel = transacciones.map((transaccion) => {
      const fechaRequest = obtenerFechaYHora(transaccion.fechaRequest);
      const fechaResponse = obtenerFechaYHora(transaccion.fechaResponse);
      return {
        nombreComercio,
        fechaRequest: fechaRequest.fecha,
        fechaResponse: fechaResponse.fecha,
        horaRequest: fechaRequest.hora,
        horaResponse: fechaResponse.hora,
        idtxs: transaccion.idTxs || "",
        comando: transaccion.comando || "",
        functionCode: transaccion.functionCode || "",
        responseCode: transaccion.responseCode || "",
        responseMessage: transaccion.responseMessage || "",
        solicitud: transaccion.request || "",
        respuesta: transaccion.response || "",
      };
    });
    setExcelInfo(dataExcel);
  }, [transacciones, comercios, selectedIdComercio]);

  const handleShowRequest = (row) => {
    try {
      let request;
      let response;
      if (props.variant === "donaciones") {
        request = JSON.parse(row.request);
        response = row.response ? JSON.parse(row.response) : "";
      } else if (props.variant === "c2c" || props.variant === "gscan") {
        const json = JSON.parse(row.request);
        request = JSON.parse(json.JsonSerialized);
        response = row.response ? JSON.parse(row.response) : "";
      }
      setDialogoJson({
        open: true,
        titulo: "Request",
        request: request ? JSON.stringify(request, null, 2) : null,
        response: response ? JSON.stringify(response, null, 2) : null,
      });
    } catch {
      setDialogoJson({
        open: true,
        titulo: "Request",
        request: "",
        response: "",
      });
    }
  };

  const handleCloseDialogoJson = () => {
    setDialogoJson({ ...dialogoJson, open: false });
  };
  return (
    <>
      <Typography variant="h5" sx={{ margin: "1rem 0" }}>
        HISTORIAL DE TRANSACCIONES {props.titulo || "POR POS"}
      </Typography>
      <ShadowBox style={{ padding: "1rem 2rem" }}>
        <Grid container spacing={2}>
          {!isComercioUserRole() && (
            <Grid item xs={12} sm={6} md={3}>
              <label style={{ color: "black" }}>SELECCIONE COMERCIO</label>
              <Combobox
                rows={comercios}
                placeholder="Seleccione comercio"
                onFilter={() => {}}
                onSelect={handleSelectComercio}
                onUnSelect={handleUnselectComercio}
                style={{ width: "200px" }}
              />
            </Grid>
          )}
          <Grid item xs={12} sm={6} md={3}>
            <label style={{ color: "black" }}>SELECCIONE POS</label>
            <Combobox
              rows={listadoPos}
              placeholder="Seleccione pos"
              onFilter={() => {}}
              onSelect={handleSelectPos}
              onUnSelect={handleUnselectPos}
              style={{ width: "200px" }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={3}></Grid>
          {!isComercioUserRole() && <Grid item xs={12} sm={12} md={3}></Grid>}
          <Grid item xs={12} sm={6} md={3}>
            <label style={{ color: "black" }}>FECHA DESDE</label>
            <SelectorFecha
              label="Fecha Desde"
              value={fechas.fechaDesde}
              onChange={handleSetFechaDesde}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <label style={{ color: "black" }}>FECHA HASTA</label>
            <SelectorFecha
              label="Fecha Hasta"
              value={fechas.fechaHasta}
              onChange={handleSetFechaHasta}
              min={fechas.fechaDesde}
              max={fechas.maxDate}
            />
          </Grid>
        </Grid>
        {!props.onlyOneCommand && (
          <>
            <br></br>
            <div>
              <label style={{ color: "black" }}>SELECCIONE COMANDO</label>
              <Combobox
                rows={listaComandos}
                placeholder="Comando..."
                onFilter={() => {}}
                onSelect={handleSelectComando}
                disableSelection={true}
                style={{ width: "200px" }}
              />
            </div>
            {comandos.length > 0 && (
              <ChipList
                listado={comandos}
                label={(x) => `${x.nombre}`}
                onDelete={handleDeleteComando}
              />
            )}
          </>
        )}
        <br></br>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <RoundButton variant="outlined" onClick={handleBuscarClick}>
            BUSCAR
          </RoundButton>
          <ExportButton
            sx={{ textAlign: "right" }}
            data={excelInfo}
            fechaDesde={
              descomponerFechaHora(new Date(fechas.fechaDesde || "")).fecha
            }
            fechaHasta={
              descomponerFechaHora(new Date(fechas.maxDate || "")).fecha
            }
            titulo={props.titulo}
          />
        </div>
        <br></br>
        
        <TransaccionesTable
          listado={transacciones}
          filterText={null}
          onShowRequest={handleShowRequest}
          variant={props.variant}
        />
        <TransaccionDialog
          titulo={dialogoJson.titulo}
          request={dialogoJson.request}
          response={dialogoJson.response}
          open={dialogoJson.open}
          onClose={handleCloseDialogoJson}
        />
      </ShadowBox>
    </>
  );
};

export default Transacciones;
